<template>
  <div>
    <div class="border border-solid border-blue-200 rounded overflow-hidden">
      <!-- <div class=""> -->
      <datatable
        :index="true"
        :url="`${this.$baseurl}/admin/admin-pcts/pending`"
        :ajax="true"
        :ajax-pagination="true"
        :exportable="true"
        :columns="pendingRepayments.columns"
        :filters="pendingRepayments.filters"
        :fillable="true"
        :actions="actions"
        ref="table"
      >
        <template #header>
          <div class="pt-4">
            <search-form
              placeholder="Search users names, emails, phone numbers, bvn, etc."
              class="mb-2"
              v-model="pendingRepayments.query"
              @submit="reloadTable"
            />
            <div class="flex items-start flex-wrap xl:flex-no-wrap">
              <div class="flex flex-wrap items-center xl:ml-auto">
                <span class="processor-filter-title">Exports:</span>
                <button
                  class="btn btn-purple-outline btn-sm mb-2 mr-2"
                  @click.prevent="$refs.table.exportAll()"
                >
                  <ion-icon name="cloud-download-outline" class="text-base" />
                  <span class="ml-2">Export All Results</span>
                </button>
                <!-- <button
                  class="btn btn-blue-outline btn-sm mb-2"
                  @click.prevent="$refs.table.exportData()"
                >
                  <ion-icon name="cloud-download-outline" class="text-base" />
                  <span class="ml-2">Export Filtered Results</span>
                </button> -->
              </div>
            </div>
          </div>
        </template>
      </datatable>
    </div>

    <modal
      className="w-full md:w-2/5 xl:w-3/10 pt-10"
      ref="repaymentRatifyModal"
    >
      <h4 class="text-2xl font-bold mb-16 text-center">
        Ratify Repayment
      </h4>

      <div>
        <template v-if="selectedRepaymentTransaction">
          <p class="text-gray-500">Name</p>
          <div class="rounded p-2">
            <div class="text-xl font-bold">
              {{
                selectedRepaymentTransaction ? selectedRepaymentTransaction.user.name : ''
              }}
              {{
                selectedRepaymentTransaction
                  ? selectedRepaymentTransaction.user.last_name
                  : ''
              }}
            </div>
          </div>

          <p class="text-gray-500">Amount</p>
          <div class="bg-blue-200 rounded p-2 text-center mb-10 h-12">
            <div class="text-xl font-bold">
              ₦{{ selectedRepaymentTransaction.amount | currency }}
            </div>
          </div>

        </template>

        <div class="text-center">
          <button
            @click.prevent="approveRepayment"
            class="button bg-blue-500 text-white hover:bg-blue-600"
            :disabled="ratifying"
          >
            <template v-if="ratifying">Ratifying...</template>
            <template v-else>Approve</template>
          </button>
          <button
            @click.prevent="failRepayment"
            class="button bg-red-500 text-white hover:bg-red-600 ml-4"
            :disabled="ratifying"
          >
            <template v-if="ratifying">Ratiying...</template>
            <template v-else>Fail</template>
          </button>
        </div>
      </div>
    </modal>
  </div>
</template>
<script>
export default {
  data() {
    return {
      actions: [
        {
          text: 'Ratify',
          class:
            'border-blue-500 text-white-500 rounded-sm px-4 py-2 hover:bg-gray-200',
          action: this.ratifyRepaymentAction
        },
      ],
      selectedRepaymentTransaction: null,
      ratifying: false,
      pendingRepayments: this.$options.resource([], {
        columns: [
          {
            name: 'user',
            th: 'User',
            render: ( _, user ) => {
              if (!user) {
                return 'N/A';
              }
              return `${user?.name} ${user?.last_name}`;
            }
          },
          {
            name: 'amount',
            th: 'Amount To Clear',
            render: (_, amount) => {
              if (amount) {
                return `<span class="text-blue-500">₦ ${this.$options.filters.currency(
                  amount
                )}</span>`;
              }
              return '<span class="text-gray-500">Invalid</span>';
            }
          },
          {
            name: 'statement',
            th: 'Due Date',
            render: (_, statement ) => {
              if (statement?.end_date) {
                return this.$options.filters.dateFormat(statement?.end_date, "D, dd M Y h:ia")
              }
              return '<span class="text-gray-500">N/A</span>';
            }
          },
          {
            name: 'statement',
            th: 'Condition',
            render: (_, statement ) => {
              switch (statement?.condition) {
                case 'unpaid':
                  return `<div class="badge badge-red-soft-outline">
                                            Unpaid
                                        </div>`;
                case 'paid':
                  return `<div class="badge badge-green-soft-outline">
                                            Paid
                        </div>`;
                default:
                  return `<div class="badge badge-red-soft-outline">
                                                Unknown
                                            </div>`;
              }
            }
          },
        ],
        filters: [
          { title: 'Unpaid', name: 'unpaid' },
          { title: 'Paid', name: 'paid' },
        ]
      }),
    };
  },
  mounted() {
    this.$options.listenForClick.apply(this);
  },
  methods: {
    async ratifyRepayment(approve) {
      let url = 'admin.repayments.failRepayment';
      if (approve) {
        url = 'admin.repayments.approveRepayment';
      }
      this.ratifying = true;
      await this.sendRequest(url, this.selectedRepaymentTransaction?.id, {
        success: response => {
          this.$refs.repaymentRatifyModal.close();
          this.$success({
            title: 'Repayment Ratified Successfully',
            body: response?.data?.message ?? 'There was an error.',
            button: 'Okay'
          });
          this.reloadTable();
        },
        error: error => {
          this.$error({
            title: 'Repayment Ratify Error',
            body: error.response?.data?.message ?? 'There was an error.',
            button: 'Okay'
          });
        }
      });
      this.ratifying = false;
      await this.$root.loadSession();
    },
    async approveRepayment() {
      await this.ratifyRepayment(true);
    },
    async failRepayment() {
      await this.ratifyRepayment();
    },
    reloadTable() {
        this.$refs.table.loadAjaxData();
    },
    ratifyRepaymentAction(repaymentTransaction) {
      this.selectedRepaymentTransaction = repaymentTransaction;
      this.$refs.repaymentRatifyModal.open();
    }
  }
};
</script>
